// scroll bar
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #1c2442;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #334172;
}
::-webkit-scrollbar-thumb:active {
  background-color: #192759;
}